import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UrlHelper } from "src/helpers/helpersUtil";
import { Toast } from "src/utils/toast";

const LogOut = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const attachedData = queryParams.get("data") ?? undefined;

  useEffect(() => {
    localStorage.removeItem("access_token");
    sessionStorage.removeItem("domain");
    if (attachedData) {
      const parsedData = JSON.parse(decodeURIComponent(attachedData));
      parsedData.signupltd &&
        sessionStorage.setItem("domain", UrlHelper.getTiccaLtdDomain());
      parsedData.easyLoginHashKey &&
        localStorage.setItem("login_hash_key", parsedData.easyLoginHashKey);

      parsedData.isSignup &&
        Toast.success(
          "You have already SignUp and Sign In. Please login to continue."
        );

      switch (parsedData.logout) {
        case "ltd":
          window.location.replace(UrlHelper.getWWWPage());
          break;
      }
    }
    navigate("/");
  }, [navigate, attachedData]);

  return <div></div>;
};

export default LogOut;
