import AuthApiServices from "../helpers/api/authApiServices";
import { Toast } from "../utils/toast";
import { UrlHelper } from "../helpers/helpersUtil";

export async function GetCrossSiteAccessToken(domain: string | null, accessToken: string, navigate: (to: string) => void, setCheckProccess?: (value: React.SetStateAction<boolean>) => void) {

    if (accessToken) {
        const newDomain = domain ? domain : UrlHelper.getTiccaDashBoardDomain();
        try {
            const ssoRes = await AuthApiServices.getSSO(newDomain)
            const urlRedirect = ssoRes?.data?.redirect_url + `/verify?jwt=${ssoRes?.data?.access_token}`;
            switch (ssoRes?.data?.status) {
                case 201:
                    window.location.replace(urlRedirect);
                    break;
                case 401:
                    // Toast.error("You're not authorized to access the domain " + newDomain);
                    window.location.replace(`https://${newDomain}/login/verify?error=unauthorized`);
                    break;
                case 400:
                case 500:
                    Toast.error("We encountered an issue while processing your login request. Please try again later.");
                    navigate("/");
                    localStorage.clear();
                    break;
                default:
                    Toast.error("We encountered an issue while processing your login request. Please try again later.");
                    navigate("/");
                    localStorage.clear();
                    break;
            }
            return ssoRes.data;
        } catch (err) {
            Toast.error("We encountered an issue while processing your login request. Please try again later.");
            navigate("/");
            localStorage.clear();
            console.log(err);
        }
    } else {
        console.log("Access token is null");
    }
    setCheckProccess &&
    setCheckProccess(true);
}
