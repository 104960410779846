export const jsonToForm = (values: Record<any, any>) => {
  const formData = new FormData();
  for (const key in values) {
    formData.append(key, values[key]);
  }
  return formData;
};

const env = process.env?.REACT_APP_STAGE_ENV;
console.log("env >>>", env);

export class UrlHelper {
  static getTiccaSignupUrl = (): string => {
    let url = "https://dev.signup.ticca.com";

    if ((process as any).env?.NODE_ENV === "development") {
      url = "https://dev.signup.ticca.com";
    }

    if (env === "dev") {
      url = "https://dev.signup.ticca.com";
    }

    if (env === "stage") {
      url = "https://stage.signup.ticca.com";
    }

    if (env === "prod") {
      url = "https://signup.ticca.com";
    }
    return url;
  };

  static getLtdSignupUrl = (): string => {
    let url = "https://dev.signup.ticca.ltd";

    if ((process as any).env?.NODE_ENV === "development") {
      url = "https://dev.signup.ticca.ltd";
    }

    if (env === "dev") {
      url = "https://dev.signup.ticca.ltd";
    }

    if (env === "stage") {
      url = "https://stage.signup.ticca.ltd";
    }

    if (env === "prod") {
      url = "https://signup.ticca.ltd";
    }
    return url;
  };
  static getLtdSignInUrl = (): string => {
    let url = "https://dev.signup.ticca.ltd/signin";

    if ((process as any).env?.NODE_ENV === "development") {
      url = "https://dev.signup.ticca.ltd/signin";
    }

    if (env === "dev") {
      url = "https://dev.signup.ticca.ltd/signin";
    }

    if (env === "stage") {
      url = "https://stage.signup.ticca.ltd/signin";
    }

    if (env === "prod") {
      url = "https://signup.ticca.ltd/signin";
    }
    return url;
  };
  static getVerifyPhone = (): string => {
    let url = "https://dev.signup.ticca.com/mobile_verification";

    if ((process as any).env?.NODE_ENV === "development") {
      url = "https://dev.signup.ticca.com/mobile_verification";
      // url = "http://localhost:3003/mobile_verification";
    }

    if (env === "dev") {
      url = "https://dev.signup.ticca.com/mobile_verification";
    }

    if (env === "stage") {
      url = "https://stage.signup.ticca.com/mobile_verification";
    }

    if (env === "prod") {
      url = "https://signup.ticca.com/mobile_verification";
    }
    return url;
  };

  static getForgotPasswordUrl = () => {
    let url = "https://signup.ticca.com";

    if ((process as any).env?.NODE_ENV === "development") {
      url = "https://dev.signup.ticca.com/forgot_password";
    }
    if (env === "dev") {
      url = "https://dev.signup.ticca.com/forgot_password";
    }

    if (env === "stage") {
      url = "https://stage.signup.ticca.com/forgot_password";
    }

    if (env === "prod") {
      url = "https://signup.ticca.com/forgot_password";
    }
    return url;
  };

  static getForgotPasswordLtdUrl = () => {
    let url = "https://signup.ticca.ltd";

    if ((process as any).env?.NODE_ENV === "development") {
      url = "https://dev.signup.ticca.ltd/forgot_password";
    }
    if (env === "dev") {
      url = "https://dev.signup.ticca.ltd/forgot_password";
    }

    if (env === "stage") {
      url = "https://stage.signup.ticca.ltd/forgot_password";
    }

    if (env === "prod") {
      url = "https://signup.ticca.ltd/forgot_password";
    }
    return url;
  };

  static getTiccaSigninUrl = (): string => {
    let url = "https://dev.signup.ticca.com/signin";

    if ((process as any).env?.NODE_ENV === "development") {
      url = "https://dev.signup.ticca.com/signin";
    }

    if (env === "dev") {
      url = "https://dev.signup.ticca.com/signin";
    }

    if (env === "stage") {
      url = "https://dev.signup.ticca.com/signin";
    }

    if (env === "prod") {
      url = "https://signup.ticca.com/signin";
    }
    return url;
  };

  static getTiccaDashBoardDomain = (): string => {
    let url = "dev.dashboard.ticca.com";

    if ((process as any).env?.NODE_ENV === "development") {
      url = "dev.dashboard.ticca.com";
    }

    if (env === "dev") {
      url = "dev.dashboard.ticca.com";
    }

    if (env === "stage") {
      url = "stage.dashboard.ticca.com";
    }

    if (env === "prod") {
      url = "dashboard.ticca.com";
    }
    return url;
  };

  static getTiccaLtdDomain = (): string => {
    let url = "dev.www.ticca.ltd";

    if ((process as any).env?.NODE_ENV === "development") {
      url = "dev.www.ticca.ltd";
    }

    if (env === "dev") {
      url = "dev.www.ticca.ltd";
    }

    if (env === "stage") {
      url = "stage.www.ticca.ltd";
    }

    if (env === "prod") {
      url = "www.ticca.ltd";
    }
    return url;
  };

  static getWWWPage = (): string => {
    let url = "https://dev.www.ticca.ltd/";

    if ((process as any).env?.NODE_ENV === "development") {
      url = "https://dev.www.ticca.ltd/";
      // url = "http://localhost:3003/mobile_verification";
    }

    if (env === "dev") {
      url = "https://dev.www.ticca.ltd/";
    }

    if (env === "stage") {
      url = "https://stage.www.ticca.ltd/";
    }

    if (env === "prod") {
      url = "https://www.ticca.ltd/";
    }
    return url;
  };
}
