import React, { useEffect, useState } from "react";
import {
  // TiccaLogoIconWhite,
  TiccaLogoTextWhite,
  // ColorPalleteIcon,
  // VersionStacksIcon,
} from "../components/icons";
import { Link, useNavigate } from "react-router-dom";
import "./MobileVerification.scss";
import { clientRoutes } from "../utils/routes";
import OtpStack from "./OtpStack";
import moment from "moment";
import { getIpAddress } from "../helpers/helpers.module";
import { AuthLocalStorageServices } from "../services/localStorage/authLocalStorageService";
import { Toast } from "../utils/toast";
import { CircularProgress } from "@mui/material";
import AuthApiServices from "../helpers/api/authApiServices";
import { GetCrossSiteAccessToken } from "../utils/functions";
import LoadingButton from "@mui/lab/LoadingButton";
import "./Login.scss";

export const getExpiryTime = () => {
  const expiry = localStorage.getItem("expiry");
  return new Date(expiry * 1000) - new Date();
};

const initialOtpValues = {
  "otp-1": "",
  "otp-2": "",
  "otp-3": "",
  "otp-4": "",
  "otp-5": "",
  "otp-6": "",
};

const MobileVerification = () => {
  const navigate = useNavigate();
  const hasRun = React.useRef(false);
  const expiryTime = getExpiryTime();
  // const location = useLocation();
  const [duration, setDuration] = useState(
    moment.duration(expiryTime, "millisecond")
  );
  const [inputValues, setInputValues] = useState(initialOtpValues);
  const [interval, setInterval] = useState(1000);
  const [targetTime, setTargetTime] = useState(localStorage.getItem("expiry"));

  const [state, setState] = useState({
    longitude: "",
    latitude: "",
    isResending: false,
    isDisabled: false,
    isDisabledSubmit: false,
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const phone = localStorage.getItem("keyword_method");
  const setCoordinates = async () => {
    if (navigator.geolocation) {
      setState({ ...state, isLoading: true });
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let lat = position.coords.latitude.toString() ?? "0.0";
          let long = position.coords.longitude.toString() ?? "0.0";

          setState({
            ...state,
            longitude: long,
            latitude: lat,
            isLoading: false,
          });
        },
        (err) => {
          console.log("We can't access your current geolocation!", err);
          Toast.warning(
            "We can't access your current geolocation, please enable your location services for best experience with us if your device supports the location services."
          );
          setState({ ...state, isLoading: false });
        }
      );
    } else {
      setState({ ...state, latitude: "360", longitude: "360" });
    }
  };

  useEffect(() => {
    if (!hasRun.current) {
      setCoordinates().then((r) => r);
      hasRun.current = true;
    }
  }, []);

  const handleSubmitOTP = async () => {
    const keys = Object.keys(inputValues);
    const otpValue = keys.map((el) => inputValues[el]).join("");

    setError("");

    if (otpValue.length !== 6 || !otpValue) {
      setError({
        message: "Please enter valid verification code!",
      });
      return;
    }

    const checkLocation = (value) => {
      if (value && value !== "") {
        return value;
      }
      return "0.0";
    };

    const values = {
      keyword: otpValue,
      ip_address: await getIpAddress(),
      hash_key: localStorage.getItem("hash_key"),
      session_key: localStorage.getItem("session_key"),
      longitude: checkLocation(state.longitude),
      latitude: checkLocation(state.latitude),
    };

    setLoading(true);
    try {
      // debugger;
      const res = await AuthApiServices.mobileVerification(values);

      if (res?.data?.status === 1) {
        AuthLocalStorageServices.tokens.setToken(res.data);
        GetCrossSiteAccessToken(
          sessionStorage.getItem("domain"),
          AuthLocalStorageServices.tokens.getAccessToken(),
          navigate
        );
      }

      // localStorage.setItem();
      if (
        res?.data?.["error_code"] &&
        (res?.data?.["error_code"] === 3 ||
          res?.data?.["error_code"] === 4 ||
          res?.data?.["error_code"] === 5 ||
          res?.data?.["error_code"] === 6 ||
          res?.data?.["error_code"] === 7 ||
          res?.data?.["error_code"] === 8)
      ) {
        setInputValues(initialOtpValues);
        setLoading(false);
        Toast.error(res?.data?.message);
      } else {
        setInputValues(initialOtpValues);
        setLoading(false);
      }

      return res.data ?? "";
    } catch (err) {
      // toast.error("Something went wrong while submitting your request!");
      console.log("Something went wrong!", err);
    }
  };

  const resendMobileVerification = async () => {
    setState({ ...state, isResending: true });
    const values = {
      ip_address: await getIpAddress(),
      form_lat: state.latitude !== "" ? state.latitude : "0.0",
      form_lon: state.longitude !== "" ? state.longitude : "0.0",
      hash_key: localStorage.getItem("hash_key"),
    };

    try {
      const res = await AuthApiServices.resendMobileVerification(values);
      if (res?.data?.status === 1) {
        Toast.success(res?.data?.message);
        localStorage.setItem("hash_key", res?.data.hash_key);
        localStorage.setItem("ownership_uid", res?.data.ownership_uid);
        localStorage.setItem("expiry", res?.data.expiry);
        localStorage.setItem("session_key", res?.data.session_key);
        const expiryTime = getExpiryTime();
        setDuration(moment.duration(expiryTime, "millisecond"));
        setInterval(1000);
        setTargetTime(res?.data.expiry);
      } else {
        if (res?.data?.["error_code"] === 3) {
          Toast.error(
            "We encountered an issue while processing your login request. Please try again later."
          );
          return;
        }
        if (
          res?.data?.["error_code"] === 7 ||
          res?.data?.["error_code"] === 5
        ) {
          Toast.error(res?.data?.message);
          setState({ ...state, isDisabled: true });
          return;
        }
        Toast.error(res?.data?.message);
      }
      setState({ ...state, isResending: false });
      return res.data ?? "";
    } catch (err) {
      // toast.error("Something went wrong while submitting your request!");
      setState({ ...state, isResending: false });
      console.log("Something went wrong!", err);
    }
  };

  return (
    <div className="mobile-verification">
      <div className="background-layer-mobile background-blur-layer"></div>
      <div className="background-image-container-mobile">
        <img src="https://public.ticca.com/images/jpg/Back_Drop.jpg" alt=" " />
      </div>

      <div className="mobile-header-container">
        <div className="logo-container">
          <Link to={clientRoutes.login} className="logo-link-container">
            <span className="logo-icon">{/* <TiccaLogoIconWhite /> */}</span>
            <span className="logo-title">
              <TiccaLogoTextWhite />
            </span>
          </Link>
        </div>
      </div>
      <div className="two-factor-text">Verify</div>
      <div className="text-factor">
        Enter your verification code sent to <br />
        {phone}
      </div>
      <div className="otp">
        <OtpStack
          duration={duration}
          error={error}
          inputValues={inputValues}
          setInputValues={setInputValues}
          setDuration={setDuration}
          fieldIntIndex={""}
          interval={interval}
          setInterval={setInterval}
          targetTime={targetTime}
        />
      </div>
      {/* <div className="time-verify">
        Your verification code will be valid for 0:59
      </div> */}
      {/* <Button
          variant="outlined"
          className={`add-button font-montserrat-500 ${
            state.isSubmitting ? "button-disabled" : ""
          }`}
          type="submit"
        >
          {state.isSubmitting ? (
            <div className="loader-container">
              <CircularProgress size="20px" />
            </div>
          ) : null}
          <span>Create Task</span>
        </Button> */}

      <div className="button-verify">
        <button
          className={`submit-button font-montserrat-500 ${
            loading ? "button-disabled" : ""
          }`}
          type="submit"
          onClick={handleSubmitOTP}
          disabled={duration <= 0 || state.isDisabledSubmit}
        >
          {loading ? (
            <div className="loader-container">
              <CircularProgress size="20px" />
            </div>
          ) : null}
          <span className="login-text font-montserrat-500">Submit</span>
        </button>
      </div>
      <div className="resend-text">
        <div>Haven’t received verification code?{""}</div>
        <LoadingButton
          loading={state.isResending}
          loadingPosition="start"
          onClick={resendMobileVerification}
          disabled={state.isDisabled}
          sx={{
            color: "white",
            padding: "0px",
            textDecoration: "underline",
            top: "-2px",
            ":hover": {
              backgroundColor: "none",
              textDecoration: "underline",
            },
            "&.Mui-disabled": {
              color: "grey", // Apply the same style as in the .css rule you mentioned
            },
          }}
        >
          Resend
        </LoadingButton>
      </div>
      <p className="back-login">
        <Link to={clientRoutes.login}>Back to login page</Link>
      </p>
    </div>
  );
};

export default MobileVerification;
